import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//image
import Iframe from 'react-iframe'
const useStyles = makeStyles( ({
    root: {
      display: 'flex',
      marginTop:'30px',
      width: '100vw',
      minHeight: '80vh',
    },
    section : {
        padding : '25vw',
        paddingTop : '10vh'        
    },
    image : {
        height: 400,
        width: 800,
        "&:hover" : {
            opacity: 0.75
        }
    }
}));

const TableauDeBord = () => {
    const classes = useStyles();
    const url = "//espelia-my.sharepoint.com/personal/matthieu_galaup_espelia_fr/_layouts/15/Doc.aspx?sourcedoc={5e68acf2-7c85-4722-9260-0164ce9f4d61}&action=embedview&wdAllowInteractivity=False&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True";
    return (
        <div className={classes.root}>
            <Iframe url={url}
                width="100%"
                height="auto"
                id="espExcel"
                display="initial"
                position="relative" 
                allow="fullscreen"                 
            />
        </div>
    )
}

export default TableauDeBord;