//navbar - toolbar
export const navbarBackgroundColor = "white";
export const toolbarBackgroundColor = "#2d2264";
//title logo  
export const colorTextTitle = '#2d2264';
/* corners css are in app.css */

//table toolbar
export const colorTextHeader = '#2d2264';
export const backGroundHeader = "#FFF";

//table headerStyle
export const tableHeaderBackgroundColor = '#2d2264';
export const tableHeaderColor = 'white';

//table row style
export const tableRowBackgroundColor = 'white';

//tab button add
export const tabButtonAddRow = "#2d2264" ;

//table text color

export const tableTextColor = '#2d2264';