import React, {useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import axios from "axios";
import {CSV_API} from "../config.js";
import { Button, TextField, Grid, Box, Typography} from '@material-ui/core';
import { saveAs } from 'file-saver';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AlertTitle,Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: 'white',

    },
    header : {
        paddingTop: 50,
        textAlign: 'center',        
        color: 'grey',
        
    },
    link : {
        "&:hover" : {
            opacity: 0.5
        }
    },
    headerSub : {
        color : "#2d2264",
        "& a" : {
            textDecoration: 'none',
            color: '#2d2264',
            
        },
    },
    button : {
        backgroundColor: "#2d2264",
        color: 'white',
        "&:hover" : {
            backgroundColor: "white",
            color: "#2d2264",
            border : '2px solid #2d2264',
        },
        borderRadius : 15,
    },
    subtitle : {
        height: 55,
        width: 220,
        backgroundColor: "#2d2264",
        borderRadius : 20,
        textAlign: 'center',

    },
    parametrage : {
        "& h3" : {
            color : 'white',
            paddingTop : 15,
        },
        backgroundColor: 'white',
        paddingLeft : 20,
        paddingRight : 20,
        paddingTop : 20,
        paddingBottom : 20
    },
    form : {
        paddingTop : 50,
        textAlign: 'center', 
        width: 250,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
        maxWidth: 500,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    inputLabel : {
        minWidth: 250
    }


}));

function getStyles(name, sirenName, theme) {
    return {
        fontWeight:
            sirenName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};
const sirenList =
[
'200041788',
'219722014',
'219722022',
'219722030',
'219722048',
'219722055',
'219722063',
'219722071',
'219722089',
'219722097',
'219722105',
'219722113',
'219722121',
'219722139',
'219722147',
'219722154',
'219722162',
'219722170',
'219722188',
'219722196',
'219722204',
'219722212',
'219722220',
'219722238',
'219722246',
'219722253',
'219722261',
'219722279',
'219722287',
'219722295',
'219722303',
'219722311',
'219722329',
'219722337',
'219722345',
'249720053',
'249720061',
'269720017',
'269720025',
'269720033',
'269720041',
'269720058',
'269720066',
'269720074',
'269720082',
'269720090',
'269720108',
'269720116',
'269720124',
'269720132',
'269720140',
'269720157',
'269720165',
'269720173',
'269720181',
'269720199',
'269720207',
'269720215',
'269720223',
'269720231',
'269720249',
'269720256',
'269720264',
'269720272',
'269720280',
'269720298',
'269720306',
'269720314',
'269720322',
'269720330',
'269720348',
'269720355',
'269720363',
'269720371',
'269720389',
'269720397',
'269720405',
'269720413',
'269720421',
'269720439',
'269720447',
'269720454',
'269720462',
'269720470',
'269720488',
'269720496',
'269720504',
'269720512',
'269720520',
'269720538',
'269720546',
'269720553',
'269720561',
'269720579',
'269720587',
'269720595',
'269720603',
'269720611',
'269720629',
'269720637',
'269720645',
'269720652',
'269720660',
'269720678',
'269720686',
];


const yearsList = [ '2014','2015', '2016', '2017','2018', '2019'];

const CircularProgressWithLabel = (props) => {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

const Accueil = () => {
    const classes = useStyles();
    const theme = useTheme();

    const [sirenName, setSirenName] = useState([]);  
    const handleChangeSiren = (event) => {
        setSirenName(event.target.value);

    };
    const [yearName, setYearName] = useState([]);
    const handlechangeYear = (event) => {
        setYearName(event.target.value);

    }

    const [error, setError] = useState({
        year: false,
        siren : false
    }
    );

    const [downloadFiles, setDownloadFiles] = useState(false);
    const [progressDown, setProgressDown] = useState(0);

    const handleclick = async () => {
        if (sirenName.length === 0) {
            setError(prevState => {
                return { ...prevState, siren: true }
            });
        }
        if (yearName.length === 0) {
            setError(prevState => {
                return { ...prevState, year: true }
            });
        }
        if ((sirenName.length === 0) || (yearName.length === 0)) {
            return
        } else {
            setError({
                year: false,
                siren: false
            });
        }
        try {
            setDownloadFiles(true);

            const param = {
                sirens: sirenName,
                years: yearName
            };
            axios({
                url: CSV_API,
                method: 'POST',
                data: param,
                responseType: 'blob', // important
                onDownloadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setProgressDown(percentCompleted)
                }
                }).then((response) => {
                //console.log(response.headers["content-disposition"]);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const contentDispositionHeader = response.headers['content-disposition'];
                const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                const fileName = result.replace(/"/g, '');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                setDownloadFiles(false);
                setProgressDown(0);
                })

        } catch (error) {
            setDownloadFiles(false);
            setProgressDown(0)
            console.log(error)
        }
    }
    


    return (
        <div  className={classes.root}>
            <header className={classes.header}>
                <h1>Extraction des balances comptables</h1>
                <h2>
                    Bienvenue sur l'utilitaire Espelia d'extraction de balances comptables
                </h2>
                <p className={classes.headerSub}>
                    Les données des balances comptables que vous allez télécharger sont extraites du site <a className={classes.link} href="//data.gouv.fr" target="_blank" rel="noopener noreferrer"><strong>data.gouv.fr</strong></a> (License ouverte v2.0 - Etalab)
                </p>
            </header>
            <section id='parametrage' className={classes.parametrage}>
                <div className={classes.subtitle}>
                    <h3>Paramétrage de l'extraction</h3>
                </div>
                <div>
                    <Grid container direction='row'>
                        <Grid item xs={5}>
                            <Grid item container direction='column'>
                                <Grid item xs={6}>
                                    <FormControl  className={classes.formControl}>
                                        <InputLabel className={classes.inputLabel} id="mutiple-name-label">SIREN concernés</InputLabel>
                                        <Select
                                        error={error.siren} 
                                        labelId="mutiple-name-label"
                                        id="mutiple-name"
                                        multiple
                                        value={sirenName}
                                        onChange={handleChangeSiren}
                                        input={<Input style={{minWidth : 200}} />}
                                        MenuProps={MenuProps}
                                        renderValue={(sirenList) => {
                                            let nameTab = [];
                                            let nameString;
                                            sirenList.sort(function(a, b){return a-b}).forEach(element => {
                                                nameTab.push(element)                                                
                                            });
                                            nameString = nameTab.join(', ');
                                        return <TextField id="standard-multiline-flexible- 
                                                        siren" 
                                                        multiline 
                                                    value={nameString} 
                                                    variant="outlined"
                                                    style={{ width: 400 }}
                                                    > 
                                                    </TextField>
                                        }}
                                        >
                                        {sirenList.map((name,index) => (
                                            <MenuItem key={index} value={name} style={getStyles(name, sirenName, theme)}>
                                            {name}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>                            
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl  className={classes.formControl}>
                                        <InputLabel className={classes.inputLabel} id="mutiple-name-label">Année(s) d'extraction</InputLabel>
                                        <Select
                                        error={error.year} 
                                        labelId="mutiple-year-label"
                                        id="mutiple-year"
                                        multiple
                                        value={yearName}
                                        onChange={handlechangeYear}
                                        input={<Input style={{minWidth : 200}}/>}
                                        MenuProps={MenuProps}                                
                                        renderValue={(yearsList) => {
                                            let nameTab = [];
                                            let nameString;
                                            yearsList.forEach(element => {
                                                nameTab.push(element)                                                
                                            });
                                            nameString = nameTab.join(', ');
                                        return <TextField id="standard-multiline-flexible- 
                                                        years" 
                                                        multiline 
                                                    value={nameString} 
                                                    variant="outlined"
                                                    style={{ width: 400 }}
                                                    > 
                                                    </TextField>
                                        }}
                                        >
                                        {yearsList.map((year) => (
                                            <MenuItem key={year} value={year} style={getStyles(year, yearName, theme)}>
                                            {year}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                        {downloadFiles ? 
                        <Alert severity="info" style={{height: 300, width: 300}}>
                            <AlertTitle>Téléchargement en cours</AlertTitle>
                            <div style={{textAlign: 'center', paddingTop: '45%'}}>
                                <CircularProgressWithLabel value={progressDown} color='secondary' size='6em'/>
                            </div>
                            </Alert>                    
                        : null }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.form}>
                    <Button  className={classes.button} onClick={() =>handleclick()}>Valider</Button>
                </div>
            </section>
            
        </div>
    )
}

export default Accueil;