/* export const CONTROLLER_URL = "http://localhost:8000/";
export const API_URL = "http://localhost:8000/api/"; */

export const CONTROLLER_URL = "https://balancescomptablesback.espedata.fr/";
export const API_URL = "https://balancescomptablesback.espedata.fr/api/";


export const USERS_API = API_URL + "users";
export const LOGINCHECK_API = API_URL + "login_check";
export const SENDEMAIL = CONTROLLER_URL + "send_email";

export const CSV_API = API_URL + "csv";
