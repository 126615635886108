import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


import Account from './account.js';

//styling
import * as styling from '../services/styling'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        //marginTop: '25vh',
    },
    titleText : {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop:'25px',
        color : styling.colorTextTitle,
        textTransform: "uppercase"
    },
    page : {
        marginTop: '50px',
    }
}));

const Administration = () => {
    const classes = useStyles();

    return (
        <div className={classes.page}>
            <Account />
        </div>
    )
}

export default Administration;
